import { memo as Memo } from 'react';

//* Components
import Page from 'components/common/Page';
import Text from 'components/common/Text';
import Container from 'components/common/Container';

//* Style
import Custom404Style from 'styles/pages/Custom404Style';

const Custom404 = Memo(() => {
	return (
		<Page
			meta={{ title: 'Backbone | 404' }}
			className={`page-404`}>
			<Custom404Style>
				<Container>
					<div className={`flex-wrapper`}>
						<Text
							tag={`h1`}
							className={`h1 gilroyMedium blackRussian left col-50 col-100`}
							text={`404`}
						/>

						<Text
							className={`h4 maisonNeueBook blackRussian right col-50 col-100`}
							text={`404Desc`}
						/>
					</div>
				</Container>
			</Custom404Style>
		</Page>
	);
});

export default Custom404;
