import styled from 'styled-components'

const Custom404Style = styled.section`
	> .container {
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.flex-wrapper {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		.left {
			font-size: calc(var(--h1) * 3);
		}

		.col-50 {
			width: 50%;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		.flex-wrapper {
			text-align: center;

			.col-100 {
				width: 100%;
			}

			.right {
				margin-top: var(--sp3x);
			}
		}
	}
`

export default Custom404Style
